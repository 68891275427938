.house {
  height: 120px;
  position: relative;
  box-sizing: border-box;
  justify-content: space-around;
  padding-top: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.content {
  overflow: hidden;
  line-height: 22px;
  padding-left: 12px;
}

.title {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 15px;
  color: #394043;
}

.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 12px;
  color: #afb2b3;
}

.price {
  font-size: 12px;
  color: #fa5741;
}

.priceNum {
  font-size: 16px;
  font-weight: bolder;
}

.tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 3px;
  padding: 4px 5px;
  margin-right: 5px;
  line-height: 12px;
}
.tag1 {
  color: #39becd;
  background: #e1f5f8;
}
.tag2 {
  color: #3fc28c;
  background: #e1f5ed;
}
.tag3 {
  color: #5aabfd;
  background: #e6f2ff;
}
