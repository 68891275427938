.box {
  width: 100%;
  padding:10px 0;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.content {
  width: 96%;
  height: 100%;
  margin-left: 2%;
  border-radius: 10px;
  background-color:#fff;
  padding:10px 10px;
  box-sizing: border-box;
}

.title {
  margin: 0;
  margin-bottom:10px;
  line-height: 28px;
  /* 文字超出显示省略号 */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}

.qty {
  /* float: right; */
  padding:4px;
  border-radius: 6px;
  background-color: rgba(0, 255, 0, 0.5);
  font-size: 14px;
  margin-right: 6px;
  /* color:#7c0b0b */
}

.flex {
  position: absolute;
  bottom:20px;
  width: 100%;
}
