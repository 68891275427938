.navBar {
  width: 100%;
  color: #333;
  background-color: #108EE9;
}

/* 全局类型 */
.navBar :global(.am-navbar-title) {
  color: #fff;
}
