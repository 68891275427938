.calendar-shortcut {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px;
  border-top: 1px solid #ddd;
  height: 42px;
}

.calendar-shortcut span {
  display: inline-block;
  color: #108ee9;
  font-size: 16px;
}

.ordertotal-box {
  width: 100%;
  height: 100%;
}

.ordertotal-box .total {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.ordertotal-sticky {
  /* background-color: #fff; */
}
